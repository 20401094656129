import YouTubeThumbnailFetcher from "./YoutubeThumbnailFetcher";

export default class YouTubeVideoPlayer {
  constructor() {
    this.videoThumbnails = document.querySelectorAll('[data-video-thumbnail]');
    this.videoIframeContainer = document.querySelector('#iframe-container');
    this.videoIframe = document.querySelector('#video-iframe');
    this.coverEl = document.querySelector('#cover-image');

    this.attachClickHandlers();
  }

  attachClickHandlers() {
    this.videoThumbnails.forEach(async thumbnail => {
      const videoId = thumbnail.dataset.videoId;
      const thumbnailFetcher = new YouTubeThumbnailFetcher(videoId);

      thumbnail.addEventListener('click', e => {
        this.toggleCoverImage();
        this.toggleIframe();
        this.setVideo(videoId);

        [...document.querySelectorAll('.product-thumbnail')].map((thumb) =>
          thumb.classList.remove('active')
        );

        e.target?.classList.toggle('active');
      });

      thumbnail.querySelector('img').src = await thumbnailFetcher.fetchThumbnail();
    });
  }

  setVideo(videoId) {
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;

    if (this.videoIframe.getAttribute('src') !== embedUrl) {
      this.videoIframe.setAttribute('src', embedUrl);
    }
  }

  toggleCoverImage() {
    this.coverEl?.classList.add('d-none');
  }

  toggleIframe() {
    this.videoIframeContainer?.classList.remove('d-none');
  }
}