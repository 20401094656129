import React, { Component, createRef } from 'react';
import HandleSearchHoc from './handle-search-hoc';
import Icon from './icon';

class SearchMobile extends Component {
  container = createRef();

  state = {
    displaySearch: false,
  };

  componentDidMount = () => {
    document.addEventListener('before_element_toggled', (e) => {
      if (['#mobile-menu', '#filter-mobile', '#sort-by-mobile'].includes(e.detail)) {
        this.setState({ displaySearch: false });
      }
    });
  };

  handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    this.setState({ displaySearch: !this.state.displaySearch }, () => {
      window.triggerScrollManager();
      window.closeFlyouts();
    });

    this.props.updateTerm('');
    this.props.updateResults();
    this.props.focusInput();
  };

  updateTermResults = (term) => {
    this.props.updateTerm(term);
    this.props.updateResults();
  };

  render() {
    return (
      <>
        <Icon selector="#mobile-search-icon">
          {this.state.displaySearch ? (
            <i className="icon icon--cross icon--white" onClick={this.handleClick}></i>
          ) : (
            <svg className="icon icon--white" onClick={this.handleClick}>
              <use xlinkHref="/themes/classic-fine-foods/assets/img/feather-sprite.svg#search" />
            </svg>
          )}
        </Icon>
        <div className={`search-mobile ${this.state.displaySearch ? 'active' : ''}`} id="mobile-search">
          <div className="search-mobile__overlay" onClick={this.handleClick} />
          <div className="search-mobile__wrap">
            <div className="container">
              <form method="POST" className="search" ref={this.container} onSubmit={this.props.handleSubmit}>
                <input type="hidden" name="controller" value="search" />
                <input ref={this.props.inputRef} type="text" className="search__input" autoComplete="off" name="s" placeholder="Search Shop" onChange={this.props.handleChange} value={this.props.term} />
                <button type="submit" className="search__btn btn btn--square btn--reset">
                  <svg className="icon">
                    <use xlinkHref="/themes/classic-fine-foods/assets/img/feather-sprite.svg#search" />
                  </svg>
                </button>
              </form>
              {this.props.results.length > 0 && (
                <div className="search-mobile__results">
                  {this.props.results.map(result => (
                    <a
                      key={result.id_product}
                      className="search-mobile__result"
                      href={result.link}
                      dangerouslySetInnerHTML={{ __html: this.props.highlightTerm(result.name, this.props.term) }}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HandleSearchHoc(SearchMobile);
