import { Slider } from '@plugins';
import { Controls, Swipe } from '@glidejs/glide/dist/glide.modular.esm';

window.addEventListener('load', () => {
  window.sliders = [];

  [...document.querySelectorAll('.glide')].map((instance) => {
    const { dataset } = instance;
    const sliderName = dataset.name;

    if (!sliderName) return false;

    const { peek, autoplay, animation, perView, displayPagination, displayArrows, selector, gap, breakpoints } = dataset;

    window.sliders[sliderName] = new Slider({ element: selector });

    // options
    if (autoplay) window.sliders[sliderName].addOption('autoplay', autoplay);
    if (animation) window.sliders[sliderName].addOption('animationDuration', animation);
    if (perView) window.sliders[sliderName].addOption('perView', parseInt(perView, 10));
    if (gap) window.sliders[sliderName].addOption('gap', parseInt(gap, 10));
    if (breakpoints) window.sliders[sliderName].addOption('breakpoints', JSON.parse(breakpoints));
    if (peek) {
      window.sliders[sliderName].addOption('peek', {
        before: 0,
        after: parseInt(peek, 10),
      });
    }

    // extensions
    if (displayArrows || displayPagination) window.sliders[sliderName].addExtension('Controls', Controls);
    window.sliders[sliderName].addExtension('Swipe', Swipe);

    window.sliders[sliderName].init();
  });
});
