const updateProductListDOM = (data) => {
  if (document.querySelector('#search_filters')) {
    document.querySelector('#search_filters').outerHTML = data.rendered_facets;
  }

  if (document.querySelector('#js-active-search-filters')) {
    document.querySelector('#js-active-search-filters').outerHTML = data.rendered_active_filters;
  }

  document.querySelector('#js-product-list-top').outerHTML = data.rendered_products_top;
  document.querySelector('#js-product-list-bottom').outerHTML = data.rendered_products_bottom;
  
  if (window.showMore) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(data.rendered_products, 'text/html');
      const rowElement = doc.querySelector('.row');
    
      if (rowElement) {
        const htmlInsideRow = rowElement.innerHTML;
    
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlInsideRow;
    
        tempDiv.removeChild(tempDiv.firstChild);
    
        const modifiedHtmlInsideRow = tempDiv.innerHTML;
    
        document.querySelector('#js-product-list .row').insertAdjacentHTML('beforeend', modifiedHtmlInsideRow);
      }
  } else {
      document.querySelector('#js-product-list').outerHTML = data.rendered_products;
  }
  
  window.showMore = false;

  window?.lazyLoad?.update();
};

const parseSearchUrl = (event) => {
  if (event.target.dataset.searchUrl !== undefined) {
    return event.target.dataset.searchUrl;
  }

  if (event.target.parentNode.dataset.searchUrl === undefined) {
    throw new Error('Can not parse search URL');
  }

  return event.target.parentNode.dataset.searchUrl;
};

document.addEventListener('DOMContentLoaded', () => {
  document.addEventListener('change', (e) => {
    if (e.target.matches('#search_filters input[data-search-url]')) {
      return prestashop.emit('updateFacets', parseSearchUrl(e));
    }
  });

  document.addEventListener('click', (e) => {
    if (e.target === document.querySelector('.js-search-filters-clear-all')) {
      e.preventDefault();
      return prestashop.emit('updateFacets', e.target.href);
    }

    if (e.target.matches('.js-search-link')) {
      e.preventDefault();
      
      if (e.target.classList.contains('show-more')) {
          window.showMore = true;
      }
      
      return prestashop.emit('updateFacets', e.target.href);
    }
  });

  prestashop.on('updateProductList', (data) => {
    updateProductListDOM(data);
  });

  prestashop.on('updateCart',  (e) => {
    const existingError = document.querySelector('.alert.alert--danger.ajax-error.m-0');
    existingError && existingError.remove();

    if (e.resp.hasError) {
      const error = `<div class="alert alert--danger ajax-error m-0" role="alert">${e.resp.errors[0]}</div>`;

      document.querySelector('#notification-container').insertAdjacentHTML('beforeend', error);
    }
  });
});
