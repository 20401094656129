import Plugin from './Plugin';

export default class ScrollReveal extends Plugin {
  constructor(selector = 'data-reveal') {
    super();

    this.selector = selector;

    this.init();
  }

  init() {
    if (!this.getElements()) return;

    this.getElements().map(element => {
      this.addObserver(element, {
        ...this.getDefaultOptions(),
        ...element.dataset
      });
    });
  }

  getDefaultOptions() {
    return {
      cb: false,
      className: 'active',
      rootMargin: '-200px',
    }
  }

  getElements() {
    return [...document.querySelectorAll(`[${this.selector}]`)] || false;
  }

  addObserver(element, options) {
    if (!('IntersectionObserver' in window)) {
      if (typeof options.cb === 'function') {
        options.cb(element);
      } else {
        element.classList.add(options.className);
      }

      return false;
    }

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (typeof options.cb === 'function') {
            options.cb(element);
          } else {
            element.classList.add(options.className)
          }

          observer.unobserve(entry.target);
        }
      })
    }, options);

    observer.observe(element);
  }
}