import Plugin from './Plugin';

export default class Tabs extends Plugin {
  constructor({ tab, content, active }) {
    super();

    this.tabSelector = tab;
    this.contentSelector = content;
    this.active = active;
    this.tabClass = this.tabSelector.replace(/(.|#)/, '');
    this.contentClass = this.contentSelector.replace(/(.|#)/, '');
    this.tabs = [...document.querySelectorAll(this.tabSelector)];
    this.contents = [...document.querySelectorAll(this.contentSelector)];

    this.init();
  }

  init() {
    if (this.tabs.filter((el) => el.classList.contains(`${this.tabSelector}${this.active}`).length === 0)) {
      this._removeActive();
      this._addActive(this.tabs[0], this.tabClass);
      this._addActive(document.getElementById(this.tabs[0].dataset.target), this.contentClass);
    }

    this._control();
  }

  _control() {
    document.body.addEventListener('click', (e) => {
      if (e.target.matches(this.tabSelector)) {
        this._removeActive();
        this._addActive(e.target, this.tabClass);
        this._addActive(document.getElementById(e.target.dataset.target), this.contentClass);
      }
    });
  }

  _removeActive() {
    this.tabs.filter((el) => el.classList.contains(`${this.tabClass}${this.active}`)).map((content) => content.classList.remove(`${this.tabClass}${this.active}`));
    this.contents.filter((el) => el.classList.contains(`${this.contentClass}${this.active}`)).map((content) => content.classList.remove(`${this.contentClass}${this.active}`));
  }

  _addActive(target, selector) {
    target.classList.add(`${selector}${this.active}`);
  }
}
