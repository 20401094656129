import Plugin from "./Plugin";

export default class ScrollTo extends Plugin {
  constructor({ element = 'data-scroll-to', target = 'data-target' } = {}) {
    super();

    this.element = element;
    this.target = target;

    this.init();
  }

  getElementSelector() {
    return `[${this.element}]`;
  }

  init() {
    document.addEventListener('click', (e) => {
      if (e.target.matches(this.getElementSelector())) {
        const target = document.querySelector(e.target.getAttribute(this.target)) || false;

        if (!target) return;

        window.scrollTo({
          top: target.offsetTop,
          left: 0,
          behavior: 'smooth'
        });
      }
    });
  }
}