import Plugin from './Plugin';

export default class Modal extends Plugin {
  constructor({ modal = '.modal', openClass = 'active', openTrigger, closeTrigger, onShow, onClose }) {
    super();

    this.modal = modal;
    this.openClass = openClass;
    this.openTrigger = openTrigger;
    this.closeTrigger = closeTrigger;
    this.onShow = onShow;
    this.onClose = onClose;

    this.init();
  }

  init() {
    document.addEventListener('click', (e) => {
      if (typeof this.openTrigger !== 'undefined') {
        if (Array.isArray(this.openTrigger)) {
          this.openTrigger.map((trigger) => {
            if (e.target.matches(trigger)) {
              this.show();
            }
          });
        } else {
          if (e.target.matches(this.openTrigger)) {
            this.show();
          }
        }
      }

      if (typeof this.closeTrigger !== 'undefined') {
        if (Array.isArray(this.closeTrigger)) {
          this.closeTrigger.map((trigger) => {
            if (e.target.matches(trigger)) {
              this.hide();
            }
          });
        } else {
          if (e.target.matches(this.closeTrigger)) {
            this.hide();
          }
        }
      }
    });
  }

  show() {
    if (document.querySelector(this.modal)) {
      document.querySelector(this.modal).classList.add(this.openClass);

      if (typeof this.onShow !== 'undefined' && typeof this.onShow == 'function') {
        this.onShow();
      }
    } else {
      console.warn(`Modal: ${this.modal} is undefined.`);
    }
  }

  hide() {
    if (document.querySelector(this.modal)) {
      document.querySelector(this.modal).classList.remove(this.openClass);

      if (typeof this.onClose !== 'undefined' && typeof this.onClose == 'function') {
        this.onClose();
      }
    } else {
      console.warn(`Modal: ${this.modal} is undefined.`);
    }
  }
}
