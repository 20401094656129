const parent = document.querySelector('body');
const config = { childList: true, subtree: true };

const callback = function(mutationsList, observer) {
  for (const mutation of mutationsList) {
    if (mutation.type === 'childList') {
      mutation.addedNodes.forEach(node => {
        if (node.id === 'js-product-list') {
          window?.lazyLoad?.update();
        }
      });
    }
  }
};

const observer = new MutationObserver(callback);

observer.observe(parent, config);