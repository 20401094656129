export default class FlyoutManager {
  constructor(elements = []) {
    this.elements = elements;

    this.bindEvents();

    window.triggerFlyoutManager = this.init;
    window.closeFlyouts = this.closeAllFlyouts;
  }

  init = (flyout) => {
    const elementsToMap = this.elements.filter((el) => el !== flyout);

    elementsToMap.map((el) => {
      const elExists = document.querySelector(el);

      if (elExists && elExists.classList.contains('flyout--active')) {
        elExists.classList.remove('flyout--active');
      }
    });
  };

  closeAllFlyouts = () => {
    this.elements.map((el) => {
      const elExists = document.querySelector(el);
      elExists && elExists.classList.remove('flyout--active');
    });
  };

  bindEvents = () => {
    // not sure why this was implemented, will re-add if required.
    // was causing bugs with the mobile menu
    // document.addEventListener('before_element_toggled', (e) => {
    //   if (!this.elements.includes(e.detail)) {
    //     this.elements.map((el) => {
    //       const elExists = document.querySelector(el);
    //       elExists && elExists.classList.remove('flyout--active');
    //     });
    //   }
    // });
  };
}
