export default class Plugin {
  constructor() {
    // expose plugin instance to the window object
    !window.Plugins && (window.Plugins = []);
    window.Plugins = {
      ...window.Plugins,
      ...{ [this.constructor.name]: this },
    };
    window.bind = this.bind;
  }

  bind() {
    window.Plugins.map((plugin) => plugin.init());
  }

  init() {
    throw new Error(`${this.constructor.name} does not implement the init method.`);
  }

  emitEvent(target, eventName) {
    const event = new CustomEvent(eventName, {
      detail: target,
    });

    document.dispatchEvent(event);
  }
}
