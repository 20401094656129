export default class YouTubeThumbnailFetcher {
  constructor(videoId) {
    this.videoId = videoId;
    this.thumbnailUrl = null;
    this.localStorageKey = `youtube_thumbnail_${videoId}`;
  }

  loadYouTubeAPI() {
    if (window.YT && typeof window.YT.Player === 'function') {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://www.youtube.com/iframe_api';
      script.onload = () => {
        resolve();
      };
      script.onerror = (error) => {
        reject(error);
      };
      document.head.appendChild(script);
    });
  }

  async fetchThumbnail() {
    try {
      await this.loadYouTubeAPI();

      const cachedThumbnail = localStorage.getItem(this.localStorageKey);
      if (cachedThumbnail) {
        this.thumbnailUrl = cachedThumbnail;
        return this.thumbnailUrl;
      }

      const playerDiv = document.createElement('div');
      playerDiv.style.display = 'none';
      document.body.appendChild(playerDiv);

      await new Promise((resolve, reject) => {
        if (window.YT && typeof window.YT.Player === 'function') {
          resolve();
        } else {
          window.onYouTubeIframeAPIReady = resolve;
        }
      });

      const player = new window.YT.Player(playerDiv, {
        videoId: this.videoId,
        events: {
          onReady: () => {
            const videoData = player.getVideoData();
            const thumbnailUrl = `https://i.ytimg.com/vi/${this.videoId}/maxresdefault.jpg`;

            localStorage.setItem(this.localStorageKey, thumbnailUrl);
            this.thumbnailUrl = thumbnailUrl;
            document.body.removeChild(playerDiv);
          }
        }
      });

      // Return the thumbnail URL
      return this.thumbnailUrl;
    } catch (error) {
      console.error('Error fetching thumbnail:', error);
    }
  }

  getThumbnailUrl() {
    return this.thumbnailUrl;
  }
}