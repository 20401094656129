const updatedProductQuantity = (e) => {
  prestashop.emit('updateProduct', {
    eventType: 'updatedProductQuantity',
    event: e,
  });
};

const toggleLoading = (targetElement) => document.querySelector(targetElement).classList.toggle('loading');

function beginTimer(e) {
  timeout = setTimeout(function () {
    updatedProductQuantity(e);
  }, 600);
}

function stopTimer() {
  clearTimeout(timeout);
}

let timeout = null;
let quantityInput = null;
let addToCartButton = null;

document.addEventListener('DOMContentLoaded', () => {
  quantityInput = document.querySelector('#quantity_wanted');
  addToCartButton = document.querySelector('#add-to-cart-button');

  prestashop.on('updateProduct', (e) => {
    toggleLoading(prestashop.selectors.product.actions);
  });

  prestashop.on('updatedProduct', (e) => {
    toggleLoading(prestashop.selectors.product.actions);
    quantityInput = document.querySelector('#quantity_wanted');
    addToCartButton = document.querySelector('#add-to-cart-button');

    let sku = document.querySelector(`option[data-product-attribute="${e.id_product_attribute}"]`) || false;

    if (sku) {
      updateReference(sku.getAttribute('data-sku'));
      updateContactLinks(sku.getAttribute('data-contact-link'))
    }
  });
});

document.addEventListener('change', (e) => {
  if (e.target.matches('#quantity_wanted')) {
    updatedProductQuantity(e);
  }
});

document.addEventListener('click', (e) => {
  const input = document.querySelector(e.target.getAttribute('data-input'));

  if (!input) return;

  if (e.target.matches('.adjust')) {
    switch (e.target.dataset.quantity) {
      case 'up':
        input.value = parseInt(input.value) + 1;
        addToCartButton.disabled = true;
        stopTimer();
        beginTimer(e);
        break;
      case 'down':
        if (parseInt(input.value) === parseInt(input.min)) {
          break;
        } else {
          input.value = parseInt(input.value) - 1;
          addToCartButton.disabled = true;
          stopTimer();
          beginTimer(e);
          break;
        }
      default:
        break;
    }
  }
});

const updateReference = (sku = '') => {
  const refEl = document.querySelector('[data-sku]') || false;

  if (!refEl) throw new Error('No SKU element in DOM to update.');

  refEl.innerText = sku;
};

const updateContactLinks = (link = '') => {
  const contactLinks = document.querySelectorAll('.product-question');

  contactLinks.forEach(function(contactLink){
    contactLink.setAttribute('href',link);
  });
};