export default class ScrollLockManager {
  constructor(elements = []) {
    this.elements = elements; // { el: '', condition: (): bool => {} }

    window.triggerScrollManager = this.init;
  }

  init = () => {
    let scrollIsLocked = false;

    this.elements.map(({ el, condition }) => {
      const elExists = document.querySelector(el);

      if (elExists && condition()) {
        scrollIsLocked = true;
      }
    });

    if (scrollIsLocked) {
      this.lockScroll();
    } else {
      this.unlockScroll();
    }
  };

  lockScroll = () => {
    document.body.style.overflow = 'hidden';
  };

  unlockScroll = () => {
    document.body.style.overflow = '';
  };
}
