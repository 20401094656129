import React, { Component } from 'react';
import { createPortal } from 'react-dom';

export default class Icon extends Component {
  container = document.querySelector(this.props.selector);

  render() {
    return createPortal(this.props.children, this.container);
  }
}
