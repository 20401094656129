const findNextList = (element) => {
  let nextElement = element.parentNode.nextElementSibling;

  while (nextElement) {
    if (nextElement.classList.contains('list')) {
      return nextElement;
    }

    nextElement = nextElement.nextElementSibling;
  }

  return null;
}

document.addEventListener('DOMContentLoaded', () => {
  const arrows = [...document.querySelectorAll('.mobile-menu__arrow')];

  arrows.map((arrow) => {
    arrow.addEventListener('click', e => {
      e.preventDefault();
      e.stopPropagation();

      const list = findNextList(arrow);
      console.log(list);
      list && list.classList.toggle('active');
    });
  });
});