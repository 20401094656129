export default class CookieManager {
  /**
   * Set/Overwrite a cookie value
   *
   * @param name
   * @param value
   * @param days      OPTIONAL Days till this cookie will stay valid. Default is current session
   * @param path      OPTIONAL domain root will be used by default
   */
  static set(name, value, days, path) {
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      var expires = '; expires=' + date.toGMTString();
    } else var expires = '';

    const dir = path || '/';
    document.cookie = name + '=' + value + expires + '; path=' + dir;
  }

  /**
   * Retrieve a cookie value
   *
   * @param name
   * @return String|null
   */
  static get(name) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i++) {
      const c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }

    return null;
  }

  /**
   * Remove a cookie
   *
   * @param name
   */
  static delete(name) {
    this.set(name, '', -1);
  }
}
