import Plugin from './Plugin';

export default class Zoom extends Plugin {
  constructor({ element = '.cover', image = '.cover__img', zoom = '.cover__zoom' } = {}) {
    super();

    this.element = document.querySelector(element) || false;
    this.image = image;
    this.zoom = zoom;

    this.init();
  }

  init() {
    if (!this.element) throw new Error('Zoom: image element does not exist.');

    this.element.addEventListener('mousemove', (e) => this.handleMouseMove(e), false);
  }

  handleMouseMove(e) {
    let original = document.querySelector(this.image);
    let style = document.querySelector(this.zoom).style;
    let x = e.pageX - (this.element.getBoundingClientRect().left + window.scrollX);
    let y = e.pageY - (this.element.getBoundingClientRect().top + window.scrollY);
    let imgWidth = original.width;
    let imgHeight = original.height;
    let xperc = (x / imgWidth) * 100;
    let yperc = (y / imgHeight) * 100;

    // Add some margin for right edge
    if (x > 0.01 * imgWidth) {
      xperc += 0.15 * xperc;
    }

    // Add some margin for bottom edge
    if (y >= 0.01 * imgHeight) {
      yperc += 0.15 * yperc;
    }

    // Set the background of the magnified image horizontal
    style.backgroundPositionX = xperc - 9 + "%";
    // Set the background of the magnified image vertical
    style.backgroundPositionY = yperc - 9 + "%";

    // Move the magnifying glass with the mouse movement.
    style.left = x - 75 + "px";
    style.top = y - 75 + "px";
  }
}