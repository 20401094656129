import { fetch as fetchPolyfill } from 'whatwg-fetch';

export default class CartStockController {
  constructor() {
    this.selector = '#proceed-to-checkout';
    this.checkoutBtn = document.querySelector(this.selector) || false;

    if(this.checkoutBtn) {
      this.stockControllerUrl = this.checkoutBtn.getAttribute('data-stock-controller') || false;
      this.bindEvents();
    }
  }

  bindEvents() {
    if (!this.checkoutBtn) {
      throw new Error(`No ${this.selector} in present in the DOM.`);
    }

    if (!this.stockControllerUrl) {
      throw new Error(`No ajax url available.`);
    }

    this.checkoutBtn.addEventListener('click', e => {
      this.handleClick(e);
    });
  }

  simulateBtnClick() {
    window.location.href = this.checkoutBtn.getAttribute('href');
  }

  async handleClick(e) {
    e.preventDefault();

    const products = this.getLowThresholdProducts();

    if (products.length < 1) {
      this.simulateBtnClick();

      return true;
    }

    this.toggleLoading();

    try {
      const res = await this.request({ products });

      this.toggleLoading();
      this.simulateBtnClick();
    } catch (err) {
      this.disableBtn();
      console.error(err);

      setTimeout(() => {
        window.location.reload();
      }, 1500)
    }
  }

  async request(payload){
    const res = await fetchPolyfill(this.stockControllerUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });

    const json = await res.json();

    if (!json.success) {
      throw new Error('Error checking stock levels for cart products.');
    }

    return json;
  }

  getLowThresholdProducts() {
    const products = [...document.querySelectorAll('[data-stock-check]')];

    if (products.length < 1) return [];

    const formattedPayload = [];

    products.map(product => {
      const productId = product.getAttribute('data-product-id');
      const productIdAttribute = product.getAttribute('data-product-id-attribute');
      const productReference = product.getAttribute('data-product-reference');
      const productLowThreshold = product.getAttribute('data-product-low-stock-threshold');

      formattedPayload.push({
        productId,
        productIdAttribute,
        productReference,
        productLowThreshold
      })
    });

    return formattedPayload;
  }

  toggleLoading() {
    this.checkoutBtn.classList.toggle('loading');
  }

  disableBtn() {
    this.toggleLoading();
    this.checkoutBtn.setAttribute('disabled', true);
  }
}