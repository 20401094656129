import Plugin from './Plugin';
import { fetch } from 'whatwg-fetch';

export default class Search extends Plugin {
  constructor({ selector = '#search', input = '.search__input', submit = '.search__btn', selectorWrapper = '.header__searchbar' } = {}) {
    super();

    this.selector = document.querySelector(selector) || false;
    this.selectorWrapper = document.querySelector(selectorWrapper) || false;

    if (!this.selector) return false;

    // elements
    this.input = this.selector.querySelector(input) || false;
    this.submit = this.selector.querySelector(submit) || false;
    this.form = this.selector.querySelector('form') || false;

    // config
    this.ajaxUrl = this.selector.dataset.ajaxUrl;
    this.perPage = this.selector.dataset.perPage;
    this.minimumCharCount = this.selector.dataset.minimumCharCount;
    this.searchTerm = '';
    this.results = [];

    this.init();
  }

  init() {
    document.addEventListener('click', (e) => {
      if (!this.selectorWrapper.contains(e.target) || e.target == this.selector.querySelector('#restart_search')) {
        this.reset();
        this.input.value = '';
      }
    });

    this.input &&
      this.input.addEventListener('keyup', async (e) => {
        const key = e.keyCode || e.charCode;

        if (key === 8 || key === 46 || e.target.value.length < 1) {
          this.reset();
          return false;
        }

        if (e.target.value.length >= this.minimumCharCount) {
          this.searchTerm = e.target.value.trim();

          await this.fetchResults();
          console.log(this.fetchResults);

          this.generateHtml();
        } else {
          this.reset();
        }
      });

    this.submit &&
      this.submit.addEventListener('click', (e) => {
        this.form.submit();
      });
  }

  generateHtml() {
    this.selector.insertAdjacentHTML('beforeend', this.renderResults());
  }

  renderResults() {
    return this.results.length > 0
      ? `
            <ul class="search__results">
                ${this.results.map((result) => this.renderResult(result.link, result.name)).join('')}
            </ul>
        `
      : '';
  }

  renderResult(link, name) {
    return `<li>
            <a href="${link}" title="${name}">
                ${name}
            </a>
        </li>`;
  }

  reset() {
    this.searchTerm = '';
    this.results = [];
    this.selector.querySelectorAll('.search__results').remove();
  }

  async fetchResults() {
    const data = await fetch(`${this.ajaxUrl}?s=${this.searchTerm}&resultsPerPage=${this.perPage}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const response = await data.json();
    console.log(response);

    if (response.products.length > 0) {
      this.results = response.products;
    }
  }
}
