document.addEventListener('DOMContentLoaded', () => {
  const catalogEl = document.querySelector('#id_document');

  if (catalogEl) {
    changeCatalog(catalogEl);
  }
});

document.addEventListener('change', (e) => {
  if (e.target.matches('#id_document')) {
    changeCatalog(e.target);
  }
});

function changeCatalog(target) {
  const path = target.value;
  const downloadCatalog = document.querySelector('#downloadCatalog');
  const option = document.querySelector('#id_document option[value="'+path+'"]');
  const download = option.dataset.download;

  if (!downloadCatalog) return;

  downloadCatalog.setAttribute('href', path);

  if(download == "1")
  {
    downloadCatalog.setAttribute('download','');
    downloadCatalog.removeAttribute('target');
    downloadCatalog.textContent = "Download";
  }
  else
  {
    downloadCatalog.removeAttribute('download');
    downloadCatalog.setAttribute('target','_blank');
    downloadCatalog.textContent = "Go To Catalog";
  }
}
