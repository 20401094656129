import Plugin from './Plugin';

export default class Toggle extends Plugin {
  constructor({ element = 'data-toggle', target = 'data-target', className = 'data-class', callback = 'data-callback' } = {}) {
    super();

    this.element = element;
    this.target = target;
    this.className = className;
    this.callback = callback;

    this.init();
  }

  getElementSelector() {
    return `[${this.element}]`;
  }

  getTargetSelector() {
    return `[${this.target}]`;
  }

  init() {
    document.addEventListener('click', (e) => {
      if (e.target.matches(this.getElementSelector())) {
        e.preventDefault();
        e.stopPropagation();

        const targetAttribute = e.target.getAttribute(this.target);

        this.emitEvent(targetAttribute, 'before_element_toggled');

        const target = document.querySelector(targetAttribute);
        const callback = e.target.getAttribute(this.callback);
        const className = e.target.getAttribute(this.className);

        target && target.classList.toggle(className);

        this.emitEvent(targetAttribute, 'after_element_toggled');
        if (typeof window[callback] === 'function') window[callback](e);
      }
    });
  }
}
