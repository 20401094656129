import React, {Component, createRef} from 'react';
import HandleSearchHoc from './handle-search-hoc';

class Search extends Component {
  container = createRef();

  componentDidMount() {
    document.addEventListener('click', (e) => this.handleClickOutside(e));
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside(e) {
    if (this.container && !this.container.current.contains(e.target)) {
      this.props.updateResults();
    }
  }

  handleClick = (term) => {
    this.props.updateTerm(term);
    this.props.updateResults();
    this.props.focusInput();
  };

  updateTerm = (index) => {
    this.props.updateTerm(this.props.results[index].term);
    this.props.updateResults();
    this.props.focusInput();
  };

  render() {
    return (
      <>
        <form method="POST" className="search" ref={this.container} onSubmit={this.props.handleSubmit}>
          <input type="hidden" name="controller" value="search" />
          <input ref={this.props.inputRef} type="text" className="search__input" autoComplete="off" name="s" placeholder="Search Shop" onChange={this.props.handleChange} value={this.props.term} />
          <button type="submit" className="search__btn btn btn--icon btn--square btn--reset">
            <svg className="icon">
              <use xlinkHref="/themes/classic-fine-foods/assets/img/feather-sprite.svg#search" />
            </svg>
          </button>
          {this.props.results.length > 0 && (
            <div className="search__results">
              {this.props.results.map(result => (
                <a
                  key={result.id_product}
                  className="search__result"
                  href={result.link}
                  dangerouslySetInnerHTML={{ __html: this.props.highlightTerm(result.name, this.props.term) }}
                />
              ))}
            </div>
          )}
        </form>
      </>
    );
  }
}

export default HandleSearchHoc(Search);
