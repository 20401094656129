import '@styles/theme.scss';
import '@js/selectors';

// vendor
import prestashop from 'prestashop';
import EventEmitter from 'events';
import LazyLoad from "vanilla-lazyload";

// pages
import '@js/pages/cart';
import '@js/pages/category';
import '@js/pages/cms';
import '@js/pages/product';

// services
import { CookieManager } from '@services';

// utils
import '@utils/product-thumbnails';
import '@utils/product-listing';
import '@utils/initialize-sliders';
import '@utils/hero';
import '@utils/mobile-menu';

// plugins
import { DatePicker, ScrollReveal, YouTubeVideoPlayer, YouTubeThumbnailFetcher, CountUpAnimator, FileUpload, WishlistController, Dismissable, ScrollTo, Toggle, Accordion, ScrollLockManager, FlyoutManager, ReactManager } from '@plugins';

// react components
import { Search, SearchMobile } from './react/search';

for (const i in EventEmitter.prototype) {
  prestashop[i] = EventEmitter.prototype[i];
}

document.addEventListener('after_element_dismissed', (e) => {
  if (e.detail === '#store-reminder') {
    // set cookie to hide reminder for X days
    // cookie name: hide_reminder_cookie
    CookieManager.set('hide_reminder_cookie',document.querySelector('#reminder-message').textContent);
  }

  if (e.detail === '#blockcart-modal') {
    window.triggerScrollManager();
  }
});

document.addEventListener('before_element_toggled', (e) => {
  const el = e.detail;

  if (!['#mobile-menu', '#filter-mobile', '#sort-by-mobile'].includes(e.detail)) return false;

  window.triggerFlyoutManager(el);
});

window.addEventListener('load', () => {
  window.lazyLoad = new LazyLoad();
});

document.addEventListener('DOMContentLoaded', () => {
  const scrollReveal = new ScrollReveal();
  const youtubePlayer = new YouTubeVideoPlayer();
  const youtubeThumbnail = new YouTubeThumbnailFetcher();
  const countUp = new CountUpAnimator();
  const fileUpload = new FileUpload();
  const wishlistController = new WishlistController();
  const scrollTo = new ScrollTo();
  const toggle = new Toggle();
  const dismissable = new Dismissable();
  const datePicker = new DatePicker();

  const reactManager = new ReactManager({
    Search: Search,
    SearchMobile: SearchMobile,
  });

  const accordion = new Accordion({
    container: '.menu',
    items: '.menu__accordion_item',
    label: '.menu__accordion_item-toggle',
    collapse: '.menu__accordion_item-content',
    activeClass: 'menu__accordion_item--active',
  });

  const flyoutManager = new FlyoutManager(['#mobile-menu', '#filter-mobile', '#sort-by-mobile']);

  const scrollLockManager = new ScrollLockManager([
    {
      el: '#mobile-menu',
      condition: () => {
        return document.querySelector('#mobile-menu').classList.contains('flyout--active');
      },
    },
    {
      el: '#filter-mobile',
      condition: () => {
        return document.querySelector('#filter-mobile').classList.contains('flyout--active');
      },
    },
    {
      el: '#sort-by-mobile',
      condition: () => {
        return document.querySelector('#sort-by-mobile').classList.contains('flyout--active');
      },
    },
    {
      el: '#mobile-search',
      condition: () => {
        return document.querySelector('#mobile-search').classList.contains('active');
      },
    },
    {
      el: '#blockcart-modal',
      condition: () => {
        return document.querySelector('#blockcart-modal') || false;
      },
    },
  ]);
});

window.ToggleFooterIcon = (e) => {
  const target = e.target;
  const iconSvg = target.querySelector('.icon use') || false;
  let updatedIcon = 'chevron-up';

  if (!iconSvg) return;

  const href = iconSvg.getAttribute('xlink:href');

  if (!href) return;

  href.includes('chevron-down') ? (updatedIcon = 'chevron-up') : (updatedIcon = 'chevron-down');
  iconSvg.setAttribute('xlink:href', href.replace(/chevron-(.*)/, updatedIcon));
};

document.addEventListener('click', (e) => {
  if (e.target.matches('.hero__control')) {
    const heroSlider = window?.sliders['hero-slider']?.slider;
    const direction = e.target.dataset.direction;

    if (!heroSlider) return false;

    heroSlider.go(direction);
  }
});
document.addEventListener("before_element_toggled", (e) => {
  if (e.detail.includes("tab")) {
    [...document.querySelectorAll(".tabs__tab")].map((tab) =>
        tab.classList.remove("active")
    );

    [...document.querySelectorAll(".tabs__content")].map((content) =>
        content.classList.remove("active")
    );
  }
});

window.tabControl = (e) => {
  e.target.classList.add("active");
};

window.truncatedTextTransition = (e) => {
  e.target.innerText =
      e.target.innerText === 'Read more' ? 'Read less' : 'Read more';
};

window.cartItemControl = e => {
  if (window.timeouts === undefined) {
    window.timeouts = [];
  }

  const toggleIcon = (iconName) => {
    const icon = e.target.querySelector('use');
    const currentIcon = icon.getAttribute('xlink:href');
    const newIcon = currentIcon.replace(/[?#].*$/, `#${iconName}`);

    icon.setAttribute('xlink:href', newIcon);
  }

  const targetSelector = e.target.getAttribute('data-target');

  if (!targetSelector) return false;

  if (window.timeouts[targetSelector] !== undefined) {
    clearTimeout(window.timeouts[targetSelector]);
    delete window.timeouts[targetSelector];

    toggleIcon('chevrons-left');

    return true;
  }

  const targetElement = document.querySelector(targetSelector);

  if (!targetElement) return false;

  toggleIcon('chevrons-right');

  window.timeouts[targetSelector] = setTimeout(() => {
    targetElement.classList.remove('cart-item--active');
    toggleIcon('chevrons-left');

    delete window.timeouts[targetSelector];
  }, 4000);
};

document.addEventListener('before_element_toggled', e => {
  if (e.detail.includes('content-')) {
    [...document.querySelectorAll('.values__tab')].map(content => (
      content.classList.remove('active')
    ));

    [...document.querySelectorAll('.values__content')].map(content => (
      content.classList.remove('active')
    ));

    setTimeout(() => {
      Object.values(window.sliders).map(instance => {
        instance.slider.update();
      });
    }, 50);
  }
});