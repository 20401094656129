import prestashop from 'prestashop';
import { fetch as fetchPolyfill } from 'whatwg-fetch';
import CartStockController from "../utils/cart-stock-controller";

prestashop.cart = prestashop.cart || {};

let hasError = false;
let isUpdateOperation = false;
let errorMsg = '';

const CheckUpdateQuantityOperations = {
  switchErrorStat: () => {
    // const $checkoutBtn = document.querySelector(prestashop.themeSelectors.checkout.btn) || false;

    if (errorMsg !== '' && !hasError) {
      // $checkoutBtn && $checkoutBtn.classList.add('disabled');
    }

    if (errorMsg !== '') {
      document.querySelector(prestashop.selectors.notifications.container).innerHTML = `<div class="alert alert--danger" role="alert" data-alert="danger">${errorMsg}</div>`;
      errorMsg = '';
      isUpdateOperation = false;
      if (hasError) {
        // if hasError is true, quantity was not updated : allow checkout
        // $checkoutBtn && $checkoutBtn.classList.remove('disabled');
      }
    } else if (!hasError && isUpdateOperation) {
      hasError = false;
      isUpdateOperation = false;
      document.querySelector(prestashop.selectors.notifications.container).innerHTML = '';
      // $checkoutBtn && $checkoutBtn.classList.remove('disabled');
    }
  },
  checkUpdateOperation: (resp) => {
    const { hasError: hasErrorOccurred, errors: errorData } = resp;
    hasError = hasErrorOccurred ?? false;
    const errors = errorData ?? '';

    if (errors instanceof Array) {
      errorMsg = errors.join(' ');
    } else {
      errorMsg = errors;
    }

    isUpdateOperation = true;
  },
};

const handleCartAction = (event) => {
  event.preventDefault();

  const target = event.target;
  const dataset = event.target.dataset;
  const cartAction = parseCartAction(target);
  const formData = new FormData();
  formData.set('ajax', '1');
  formData.set('action', 'update');

  if (typeof cartAction === 'undefined') {
    return;
  }
  const res = fetchPolyfill(cartAction.url, {
    method: 'POST',
    body: formData
  })
      .then(res => res.json())
      .then((data) => {
        CheckUpdateQuantityOperations.checkUpdateOperation(data);

        prestashop.emit('updateCart', {
          reason: dataset,
          resp: data
        });
      })
      .catch((err) => {
        prestashop.emit('handleError', {
          eventType: 'updateProductInCart',
          resp: err,
          cartAction: cartAction.type,
        });
      });
};

const checkProductMaxAndMinOrder = () => {
  const checkoutBtn = document.querySelector('#proceed-to-checkout');
  
  if (!checkoutBtn) return;
  
  var messages = document.querySelectorAll('.max-quantity-message');
  checkoutBtn.removeAttribute('style');

  var minOrderReached = true;
  var dailyMaxExceeded = false;
  var minOrder = 0;

  messages.forEach(element => {
    if(element.dataset.proceed == 0 || element.dataset.minorder == 0)
    {
      document.querySelector('#proceed-to-checkout').setAttribute('style','display:none;');

      if(element.dataset.proceed == 0)
      {
        dailyMaxExceeded = true;
      }

      if(element.dataset.minorder == 0)
      {
        minOrderReached = false;
      }

      minOrder = element.dataset.minorderamount;
    }
  });

  document.querySelector(prestashop.selectors.notifications.container).innerHTML = '';

  if(!minOrderReached)
  {
    document.querySelector(prestashop.selectors.notifications.container).innerHTML += '<div class="alert alert--danger" role="alert" data-alert="danger">Minimum £'+minOrder+' needed in cart to proceed to checkout. Free shipping over £250.</div>';
  }

  if(dailyMaxExceeded)
  {
    document.querySelector(prestashop.selectors.notifications.container).innerHTML += `<div class="alert alert--danger" role="alert" data-alert="danger">Daily maximum quantity exceeded for one or more products.</div>`;
  }
}

const parseCartAction = (target) => {
  const cartAction = {};
  cartAction.url = target.getAttribute('href');
  cartAction.type = target.getAttribute('data-link-action');

  if (target.dataset.upUrl) {
    cartAction.type = 'increaseProductQuantity';
  }
  
  if (target.dataset.downUrl) {
    cartAction.type = 'decreaseProductQuantity';
  }

  return cartAction;
};

document.addEventListener('click', (event) => {
  switch (true) {
    case event.target.matches('.js-remove-from-cart'):
    case event.target.matches('.js-update-quantity'):
    // case event.target.matches('a[data-link-action="remove-voucher"]'):
      handleCartAction(event);
      break;
  }
});

document.addEventListener('focusout', (event) => {
  // handleCartAction(event);
});

document.addEventListener('DOMContentLoaded', () => {
  const stockController = new CartStockController();
  checkProductMaxAndMinOrder();

  prestashop.on('updatedCart', () => {
    CheckUpdateQuantityOperations.switchErrorStat();
    checkProductMaxAndMinOrder();
  });
});

// document.addEventListener('focusout', async (event) => {
//   switch (true) {
//     case event.target.matches('.product-quantity'):
//       const url = event.target.dataset.link;
//       const baseQuantity = event.target.getAttribute('value');
//       const quantity = event.target.value;
//       const qty = quantity - baseQuantity;
//
//       if (quantity < 0 || isNaN(quantity)) {
//         event.target.setAttribute(baseQuantity);
//       }
//
//       if (qty === 0) {
//         return;
//       }
//
//       const data = new FormData();
//       data.set('ajax', 1);
//       data.set('qty', Math.abs(qty));
//       data.set('action', 'update');
//       data.set('op', qty > 0 ? 'up' : 'down');
//
//       const resp = await axios.post(url, data);
//       event.target.setAttribute('value', resp.data.quantity);
//
//       prestashop.emit('updateCart', {
//         resp: event.target.dataset,
//       });
//
//       break;
//   }
// });