import 'yet-another-abortcontroller-polyfill';
import React, { Component, createRef } from 'react';
import { fetch as fetchPolyfill } from 'whatwg-fetch';

const abortableFetch = 'signal' in new Request('') ? window.fetch : fetchPolyfill;

export default (WrappedComponent, props = {}) => {
  return class HandleSearchHoc extends Component {
    state = {
      term: this.props.term || '',
      results: [],
    };

    abortController = null;

    inputRef = createRef();

    updateTerm = (term = '') => this.setState({ term }, this.focusInput);

    updateResults = (results = []) => this.setState({ results });

    handleSubmit = (e) => {
      e.preventDefault();

      const url = new URL(this.props.ajax_url);
      url.searchParams.set('s', this.state.term);

      window.location.href = url;
    };

    handleChange = (e) => {
      this.updateTerm(e.target.value);
      this.doSearch(e.target.value);
    };

    focusInput = () => {
      // browser fix, wait for render before focus
      setTimeout(() => {
        this.inputRef.current.focus();
      }, 50);
    };

    doSearch = (searchTerm) => {
      if (searchTerm.length < this.props.minlength) {
        this.setState({ results: [] });
        return;
      }

      try {
        this.abortController?.abort();
        this.abortController = new AbortController();

        const formData = new FormData();
        formData.set('s', searchTerm);
        formData.set('resultsPerPage', 10);

        abortableFetch(this.props.ajax_url, {
          method: 'POST',
          headers: {
            "Accept": "application/json",
          },
          body: formData,
          signal: this.abortController.signal,
        })
          .then((res) => res.json())
          .then((res) => {
            this.abortController = null;
            this.setState({ results: res.products });
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log(err);
      }
    };

    highlightTerm = (inputString, searchTerm) => {
      const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      const regex = new RegExp(`(${escapedSearchTerm})`, 'gi');

      return inputString.replace(regex, '<strong>$1</strong>');
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          doSearch={this.doSearch}
          inputRef={this.inputRef}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          updateTerm={this.updateTerm}
          focusInput={this.focusInput}
          updateResults={this.updateResults}
          highlightTerm={this.highlightTerm}
        />
      );
    }
  };
};
