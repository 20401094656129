export default class CountUpAnimator {
  constructor(selector = '[data-animate]') {
    this.elements = document.querySelectorAll(selector);
    this.animationDuration = 4000;
    this.delay = 500;

    this.startAnimation();
  }

  startAnimation() {
    const options = {
      threshold: 0.8
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach((entry, index) => {
        if (entry.isIntersecting && !entry.target.dataset.animated) {
          const animateTo = parseInt(entry.target.getAttribute('data-animate-to'));

          setTimeout(() => {
            this.animateCountUp(entry.target, animateTo);
            entry.target.dataset.animated = true;
          }, index * this.delay);
        }
      });
    }, options);

    this.elements.forEach(element => {
      observer.observe(element);
    });
  }

  animateCountUp(element, animateTo) {
    let startTime = null;
    let currentCount = 0;

    const updateCount = (timestamp) => {
      if (!startTime) startTime = timestamp;

      const progress = timestamp - startTime;
      const percentage = Math.min(progress / this.animationDuration, 1);

      currentCount = Math.round(percentage * animateTo);
      element.textContent = currentCount;

      if (percentage < 1) requestAnimationFrame(updateCount);
    };

    requestAnimationFrame(updateCount);
  }
}