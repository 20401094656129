import Plugin from "./Plugin";
import { Datepicker } from 'vanillajs-datepicker';
import 'vanillajs-datepicker/css/datepicker-bulma.css';

export default class DatePicker extends Plugin {
  constructor( selector = '[data-datepicker]') {
    super();

    this.selector = selector;
    this.defaultOptions = {format:'dd/mm/yyyy',enableOnReadonly:true,weekStart:1};

    this.init();
  }

  getAllDatePickers() {
    return [...document.querySelectorAll(this.selector)] || [];
  }

  getOptions(elem) {
    var datesDisabledElem = elem.dataset.datesDisabled;
    var datesDisabled = [];

    if(datesDisabledElem)
    {
      datesDisabled = datesDisabledElem.split(',');
    }

    return {
      ...this.defaultOptions,
      ...elem.dataset,
      datesDisabled,
    };
  }

  init() {
    const dp = this.getAllDatePickers();

    if (dp.length < 1) {
      console.warn('No datepickers available.');

      return false;
    }

    dp.map(picker => this.createDatePicker(picker));
  }

  createDatePicker(elem) {
    const options = this.getOptions(elem);
    const datepicker = new Datepicker(elem, options);
  }
}