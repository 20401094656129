import { createElement } from 'react';
import { createRoot } from 'react-dom/client';

export default class ReactManager {
  constructor(components = {}, dataAttr = 'data-component', propsAttr = 'data-props') {
    this.components = components;
    this.dataAttr = dataAttr;
    this.propsAttr = propsAttr;

    this.injectComponents();
  }

  injectComponents() {
    for (const [key, value] of Object.entries(this.components)) {
      const domElem = document.querySelector(this.buildSelector(key));

      if (!domElem) return false;

      const component = createElement(value, this.getComponentProps(domElem));
      const root = createRoot(domElem);

      root.render(component);
    }
  }

  buildSelector(value) {
    return `[${this.dataAttr}="${value}"]`;
  }

  getComponentProps(el) {
    return JSON.parse(el.getAttribute(this.propsAttr)) || {};
  }
}
