  export default (() => {
    window.addEventListener('load', () => {
      const heroSlider = window.sliders['hero-slider'];

      if (!heroSlider) return false;

      heroSlider.slider.on(['build.after', 'run'], () => {
        const currentIndex = heroSlider.slider.index;
        const currentSlide = [...document.querySelectorAll('[data-slide]')][currentIndex];
        const { img, alt } = currentSlide.dataset;

        const target = document.querySelector('#hero-img');

        if (!target) return;

        target.setAttribute('src', img);
        target.setAttribute('alt', alt);
      });
    });

    return true;
  })();