import Plugin from './Plugin';
import { fetch } from 'whatwg-fetch';

export default class WishlistController extends Plugin {
  constructor({ btn = 'data-wishlist', productId = 'data-product-id', ajaxUrl = 'data-ajax-url', productAttributeId = 'data-product-attribute-id' } = {}) {
    super();

    this.btn = btn;
    this.ajaxUrl = ajaxUrl;
    this.productId = productId;
    this.productAttributeId = productAttributeId;

    this.init();
  }

  init = () => {
    this.bindEvents();
    this.updateWishlistIcons();
  };

  getExistingFavouritedProducts = () => {
    const wishlistProducts = prestashop?.modules?.teapotfavouriteproducts?.favourite_products_cookie || [];
    const formattedProducts = [];

    wishlistProducts.map((product) => formattedProducts.push(product.id_product+" - "+product.id_product_attribute));

    return formattedProducts;
  };

  updateWishlistIcons = () => {
    const wishlistProducts = this.getExistingFavouritedProducts();

    this.getWishlistBtns().map((btn) => {
      const productId = this.getProductId(btn);
      const productAttributeId = this.getProductAttributeId(btn);
      const combination = productId+ " - "+productAttributeId;

      if (wishlistProducts.includes(combination)) {
        this.updateWishlistIcon(btn);
      }
    });
  };

  updateWishlistIcon = (btn) => {
    btn.querySelector('.icon')?.classList.add('icon--red');
  };

  updateWishlistIconAfterAjax= (btn) => {
    if(btn.querySelector('.icon').classList.contains('icon--red'))
    {
      btn.querySelector('.icon')?.classList.remove('icon--red');

      if(document.querySelector('.icon-wishlist-desktop'))
      {
        document.querySelector('.icon-wishlist-desktop').classList.remove('icon--red');
      }

      if(document.querySelector('.icon-wishlist-mobile'))
      {
        document.querySelector('.icon-wishlist-mobile').classList.remove('icon--red');
      }
    }
    else
    {
      btn.querySelector('.icon')?.classList.add('icon--red');

      if(document.querySelector('.icon-wishlist-desktop'))
      {
        document.querySelector('.icon-wishlist-desktop').classList.add('icon--red');
      }

      if(document.querySelector('.icon-wishlist-mobile'))
      {
        document.querySelector('.icon-wishlist-mobile').classList.add('icon--red');
      }
    }
  };

  getProductId = (btn) => {
    const productId = btn.getAttribute(this.productId) || null;

    if (!productId) {
      throw new Error(`Attribute missing: ${this.productId}`);
    }

    return productId;
  };

  getProductAttributeId = (btn) => {
    const productAttributeId = btn.getAttribute(this.productAttributeId) || null;

    if (!productAttributeId) {
      throw new Error(`Attribute missing: ${this.productAttributeId}`);
    }

    return productAttributeId;
  };

  getAjaxUrl = (btn) => {
    const ajaxUrl = btn.getAttribute(this.ajaxUrl) || false;

    if (!ajaxUrl) {
      //throw new Error(`Attribute missing: ${this.ajaxUrl}`);
    }

    return ajaxUrl;
  };

  getWishlistBtns = () => [...document.querySelectorAll(`[${this.btn}]`)] || [];

  bindEvents = () => {
    document.addEventListener('click', async (e) => {
      if (e.target.matches(`[${this.btn}]`) || e.target.classList.contains('icon-wishlist-mobile')) {
        e.preventDefault();
        e.stopPropagation();

        var btn = e.target;

        if(!this.getAjaxUrl(btn))
        {
          btn = btn.parentElement;
        }

        try {
          const ajaxUrl = this.getAjaxUrl(btn);
          const productId = this.getProductId(btn);
          const productAttributeId = this.getProductAttributeId(btn);

          await this.request(ajaxUrl, productId, productAttributeId);

          this.updateWishlistIconAfterAjax(btn);
        } catch (error) {
          console.warn(error);
        }
      }
    });
  };

  request = async (url, productId, productAttributeId) => {
    const payload = new FormData();
    payload.set('data[productId]', productId);
    payload.set('data[productAttributeId]', productAttributeId);
    payload.set('data[customerLogged]', 1);
    payload.set('ajax', 1);

    const res = await fetch(`${url}`, {
      method: 'POST',
      body: payload,
    });

    const data = await res.json();

    if (!data.success) {
      throw new Error('Unable to add to wishlist.');
    }

    return data;
  };
}
