export default (() => {
    document.addEventListener('click', (e) => {
        if (e.target.matches('[data-thumbnail]')) {
            const target = e.target.getAttribute('data-target');
            const id = e.target.getAttribute('data-id');
            const targetEl = document.querySelector(target);
            const imgSrc = e.target.getAttribute('data-image-large-src');

            if (!targetEl) return false;

            [...document.querySelectorAll('.product-thumbnail')].map((thumb) =>
                thumb.classList.remove('active')
            );

            e.target.parentNode.classList.add('active');
            targetEl.classList.remove('d-none');
            targetEl.src = imgSrc;
            targetEl.dataset.current = id;

            document.querySelector('#iframe-container').classList.add('d-none');
        }
    });

    return true;
})();