import Plugin from "./Plugin";

export default class FileUpload extends Plugin {
    constructor({ selector = 'data-file-upload' } = {}) {
        super();

        this.selector = selector;

        this.init();
    }

    init() {
        this.getFileUploads().map(upload => {
            try {
                this.bindEvents(upload);
            } catch (e) {
                console.warn(e);
            }
        });
    }

    getFileUploads() {
        return [...document.querySelectorAll(`[${this.selector}]`)] || false;
    }

    bindEvents(upload) {
        const input = upload.querySelector('input');
        const target = upload.querySelector('[data-file-input]');

        if (!input || !target) {
            throw new Error(`Element not found.`);
        }

        input.addEventListener('change', e => {
            target.innerHTML = e.target?.files[0]?.name;
        });
    }
}