import Plugin from './Plugin';

export default class Dismissable extends Plugin {
  constructor({ element = 'data-dismissable', target = 'data-target' } = {}) {
    super();

    this.element = element;
    this.target = target;

    this.init();
  }

  getElementSelector() {
    return `[${this.element}]`;
  }

  getTargetSelector() {
    return `[${this.target}]`;
  }

  init() {
    document.addEventListener('click', (e) => {
      if (e.target.matches(this.getElementSelector())) {
        e.stopPropagation();

        const targetAttribute = e.target.getAttribute(this.target);

        if (!targetAttribute) {
          throw new Error(`data-target attribute not found: ${targetAttribute}`);
        }

        const target = document.querySelector(targetAttribute);

        if (!target) {
          throw new Error(`Target DOM element not found.`);
        }

        this.emitEvent(targetAttribute, 'before_element_dismissed');

        target.parentNode.removeChild(target);

        this.emitEvent(targetAttribute, 'after_element_dismissed');
      }
    });
  }
}
