import Plugin from "./Plugin";

export default class Hover extends Plugin {
    constructor({ containers, hoverElements, hoverElementsTargets, hoverElementsActiveClass, activeClass, alwaysShowFirstEl = false, maxMobileSize = 991 }) {
        super();
        this.containers = document.querySelectorAll(containers);
        this.hoverElements = hoverElements;
        this.hoverElementsTargets = hoverElementsTargets;
        this.hoverElementsActiveClass = hoverElementsActiveClass;
        this.activeClass = activeClass;
        this.alwaysShowFirstEl = alwaysShowFirstEl;
        this.maxMobileSize = maxMobileSize;
        
        this.init();
        window.addEventListener('resize', () => { this.init() });
    }

    hoverElementsToLookFor() {
        return `[${this.hoverElements}]`;
    }

    hoverEvents(e, hoveredEl, targetEl) {
        if (hoveredEl.contains(e.target) || targetEl.contains(e.target)) {
            hoveredEl.classList.add(this.hoverElementsActiveClass);
            targetEl.classList.add(this.activeClass);
        } else {
            hoveredEl.classList.remove(this.hoverElementsActiveClass);
            targetEl.classList.remove(this.activeClass);
        }
    }

    // Lots of functions below could be cleaned up and amalgamated into one to prevent spaghetti code.
    firstElementFilters() {
        if (this.alwaysShowFirstEl) {
            this.containers.forEach(container => {
                const containerElements = container.querySelectorAll(this.hoverElementsToLookFor());

                containerElements.forEach((element, index) => {
                    const target = container.querySelector(element.getAttribute(this.hoverElementsTargets));                
                    if (index == 0 && target) {
                        element.classList.add(this.hoverElementsActiveClass);
                        target.classList.add(this.activeClass);
                    } else {
                        element.classList.remove(this.hoverElementsActiveClass);
                        target.classList.remove(this.activeClass);
                    }
                });
            });
        }
    }

    reset() {
        this.containers.forEach(container => {
            const containerElements = container.querySelectorAll(this.hoverElementsToLookFor());

            containerElements.forEach((element) => {
                const target = container.querySelector(element.getAttribute(this.hoverElementsTargets));                
                if (target) {
                    element.classList.remove(this.hoverElementsActiveClass);
                    target.classList.remove(this.activeClass);
                }
            });
        });

        this.firstElementFilters();
    }

    init() {
        if (this.containers && window.innerWidth > this.maxMobileSize) {
            this.firstElementFilters();

            this.containers.forEach(container => {
                const containerElements = container.querySelectorAll(this.hoverElementsToLookFor());
                
                // Need to add removeeventlistener for under 992px
                // - Ideally there should be a callback function on each mousemove event listener which will kill and re-initiate the arrow function nested in it,
                // - this would then run on document init AND we'd attach it to a resize window event listener.
                // - When the window would resize, the callback function would have some functionality that says "if > mobSize then initiate, else dont".
                container.addEventListener('mousemove', (e) => {
                    containerElements.forEach((element) => {
                        const target = container.querySelector(element.getAttribute(this.hoverElementsTargets));
    
                        if (target) {
                            this.hoverEvents(e, element, target);
                        }
                    });
                });

                container.addEventListener('mouseleave', () => {
                    this.reset();
                })
            });
        }
    }
}