import Plugin from './Plugin';
import Glide from '@glidejs/glide';
import '@glidejs/glide/src/assets/sass/glide.core.scss';

export default class Slider extends Plugin {
  defaultOptions = {
    type: 'carousel',
    startAt: 0,
    perView: 1,
    gap: 0,
    breakpoints: {
      544: {
        perView: 1,
      },
    },
  };

  constructor({ element, debug = false }) {
    super();

    this.element = document.querySelector(element);
    this.selector = element;
    this.debug = debug;

    if (!this.element) throw new Error(`${element} does not exist.`);

    this.slider = null;
    this.extensions = {};
    this.options = {};
  }

  init() {
    this.slider = new Glide(this.selector, { ...this.defaultOptions, ...this.options });

    this.slider.on(['build.before'], () => {
      document.querySelector(this.selector).classList.add('visible');
    });

    this.slider.mount(this.extensions);
  }

  getElement() {
    return this.element;
  }

  getGlideInstance() {
    return this.slider;
  }

  addExtension(key, ext) {
    this.extensions[key] = ext;
  }

  addOption(key, opt) {
    this.options[key] = opt;
  }
}
