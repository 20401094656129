import Plugin from './Plugin';
import { CacheManager } from '@services';

export default class Listing extends Plugin {
    constructor({
        gridSelector = '#grid',
        listSelector = '#list',
        miniature = 'article.product',
        container = 'listing-manager',
        containerClass = 'listing-manager__alternate',
        gridClasses = ['col-6', 'col-md-6', 'col-lg-4'],
        listClasses = ['product--list', 'col-12']
    } = {}) {
        super();

        this.gridSelector = gridSelector;
        this.listSelector = listSelector;
        this.miniature = miniature;
        this.container = document.getElementById(container);
        this.containerClass = containerClass;
        this.gridClasses = gridClasses;
        this.listClasses = listClasses;

        this.cacheManager = new CacheManager();

        this.init();
    }

    init() {
        document.addEventListener('DOMContentLoaded', e => {
            const setting = this.cacheManager.getItem('listing');

            if (setting && setting === 'list') {
                this.toggleGridClasses('remove');
                this.toggleListClasses();
                this.container.classList.add(this.containerClass);
            }
        });

        document.addEventListener('click', e => {
            if (e.target.matches(this.gridSelector)) {
                e.stopPropagation();

                this.cacheManager.setItem('listing', 'grid');

                this.toggleGridClasses();
                this.toggleListClasses('remove');
                this.container.classList.remove(this.containerClass);
            }

            if (e.target.matches(this.listSelector)) {
                e.stopPropagation();

                this.cacheManager.setItem('listing', 'list');

                this.toggleGridClasses('remove');
                this.toggleListClasses();
                this.container.classList.add(this.containerClass);
            }
        })
    }

    getMiniatures() {
        return [...document.querySelectorAll(this.miniature)] || false;
    }

    getContainer() {
        return [...document.querySelectorAll(this.container)] || false;
    }

    toggleGridClasses(type = 'add') {
        this.getMiniatures().map(miniature => {
            this.gridClasses.map(className => miniature.classList[type](className));
        });
    }

    toggleListClasses(type = 'add') {
        this.getMiniatures().map(miniature => {
            this.listClasses.map(className => miniature.classList[type](className));
        });
    }

    // toggleContainerClasses(type = 'add') {
    //     this.getContainer().map(container => {
    //         this.containerClasses.map(className => container.classList[type](className));
    //     });
    // }
}